import { useEffect, useRef } from "react";
import type { UseFormReturn, FieldValues } from "react-hook-form";
import { toast } from "sonner";

// Flatten error object into an array of unique path-message pairs
const flattenErrors = (
  errors: any,
  path: string[] = [],
): { path: string; message: string }[] => {
  let errorMessages: { path: string; message: string }[] = [];

  if (errors?.message) {
    const errorPath = path.join(" -> ");
    errorMessages.push({ path: errorPath, message: errors.message });
  }

  if (typeof errors === "object" && errors !== null) {
    Object.entries(errors).forEach(([key, value]) => {
      errorMessages = errorMessages.concat(
        flattenErrors(value, [...path, key]),
      );
    });
  }

  return errorMessages;
};

export const useShowErrors = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
) => {
  const shownErrorsRef = useRef<Set<string>>(new Set());
  const lastValidationRef = useRef<number>(0);

  useEffect(() => {
    const errors = form.formState.errors;
    const flattenedErrors = flattenErrors(errors);
    const currentErrors = new Set<string>();

    // Check if the form was validated again (e.g., user clicked save again)
    const currentValidationTime = Date.now();
    if (currentValidationTime - lastValidationRef.current > 500) {
      // Reset previous error tracking to allow re-showing the same errors
      shownErrorsRef.current.clear();
      lastValidationRef.current = currentValidationTime;
    }

    flattenedErrors.forEach(({ message }) => {
      const errorKey = `${message}`;

      // Always show error toast, even if it's the same error again
      toast.error(errorKey, {
        id: errorKey,
        duration: 5000,
        style: {
          color: "white",
          border: "1px solid darkred",
        },
        className: "!bg-destructive",
      });

      currentErrors.add(errorKey);
    });

    // Dismiss only the errors that are no longer present
    shownErrorsRef.current.forEach((prevError) => {
      if (!currentErrors.has(prevError)) {
        toast.dismiss(prevError);
      }
    });

    // Update the reference with the current errors
    shownErrorsRef.current = currentErrors;
  }, [JSON.stringify(form.formState.errors)]);
};

// import { useEffect } from "react";
// import type { FieldValues, UseFormReturn } from "react-hook-form";
// import { toast } from "sonner";

// export const useShowErrors = <TFieldValues extends FieldValues>(
//   form: UseFormReturn<TFieldValues>,
// ) => {
//   useEffect(() => {
//     const errors = form.formState.errors;
//     if (Object.keys(errors).length > 0) {
//       Object.entries(errors).forEach(([fieldName, error]) => {
//         toast.error(`${error?.message}`, {
//           duration: 5000,
//           style: {
//             color: "white",
//             border: "1px solid darkred",
//           },
//           className: "!bg-destructive",
//         });
//       });
//     }
//   }, [form.formState.errors]);
// };
