import {
  Home,
  BarChart3,
  Users,
  FileText,
  Bell,
  Package,
  Database,
  AlertCircle,
  Settings,
  ArrowUpRightFromSquare,
  ShieldCheck,
} from "lucide-react";

const useGetSidebarData = (isAdmin: boolean, isOrgAdmin: boolean) => {
  const menuItems = [
    { id: 0, href: "/", label: "Home", Icon: Home },
    { id: 1, href: "/dashboard", label: "Dashboard", Icon: Home },
    { id: 2, href: "/arr", label: "ARR", Icon: BarChart3 },
    {
      id: 3,
      href: "/customers",
      label: "Customers",
      Icon: Users,
    },
    {
      id: 4,
      href: "/invoices",
      label: "Invoices",
      Icon: FileText,
    },
    {
      id: 5,
      href: "/notifications",
      label: "Notifications",
      Icon: Bell,
    },
    {
      id: 6,
      href: "/products",
      label: "Products",
      Icon: Package,
    },
  ];

  const SubMenuData = [
    {
      label: "Data Lab",
      Icon: Database,
      DataLabAlertIcon: AlertCircle,
      dataLabAlert: true,
      canRender: true,
      subMenuItems: [
        {
          href: "/data-labs/customers",
          label: "Customers",
          subMenuAlert: true,
        },
        {
          href: "/data-labs/products",
          label: "Products",
          subMenuAlert: true,
        },
      ],
    },
    {
      label: "Configuration",
      Icon: Settings,
      canRender: isOrgAdmin,
      subMenuItems: [
        {
          href: "/team",
          label: "Team",
        },
        {
          href: "/integrations",
          label: "Integrations",
        },
        {
          href: "/",
          label: "Api",
          hasSubMenu: true,
          secondSubMenuItems: [
            {
              href: "/configuration/api-keys",
              label: "Api-keys",
            },
            {
              href: "https://app.theneo.io/c7fccff2-6701-40eb-8ca3-7bcccf801727/cube9",
              label: "Documentation",
              externalLink: true,
              IconRight: ArrowUpRightFromSquare,
            },
          ],
        },
        {
          href: "/settings",
          label: "Settings",
        },
      ],
    },
    {
      label: "Admin",
      Icon: ShieldCheck,
      canRender: isAdmin,
      subMenuItems: [
        {
          href: "/admin/misc-operations",
          label: "Misc Operations",
        },
        {
          href: "/admin/test-integrations",
          label: "Test Integrations",
        },
      ],
    },
  ];
  return { menuItems, SubMenuData };
};

export default useGetSidebarData;
